import React, {
  useContext,
  useState,
  useEffect,
  ChangeEvent,
  FC,
  useMemo,
} from 'react';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { Breadcrumbs, Typography } from '@material-ui/core';
import Skeleton from 'react-loading-skeleton';
import RetailerInformationContext from '../../context/RetailerInformationContext';
import { useGetInventorySearch } from '../../api/ShoppingApi/clients/SWRCartClient';
import { InventoryRecord } from '../../api/responses/InventoryResponse';
import InventorySearchParams, {
  TagFiltersTypes,
} from '../../types/InventorySearchParams';
import { emojiCheck } from '../../utilities/validation';
import { RangeFormatter } from '../../utilities/RangeFormatter';
import ImageCover from '../../components/ImageCover';
import PaginationControls from '../../components/PaginationControls';
import FilterTags from '../../components/FilterTags';
import FilterList from '../../components/FilterList';
import IconSearch from '../../components/Icons/IconSearch';
import PlaceholderImage from '../../images/inventory_image_1024x1024.png';
import Styles from './ProductInventory.module.scss';
import ApplicationContext from '../../context/ApplicationContext';

const ProductInventoryCollections: FC = () => {
  const theme = useTheme();
  const styles = makeStyles({
    productGroupImage: {
      border: '1px solid #d3d3d3',
      '&:hover': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    },
    productGroupItem: {
      border: 'none',
      '& a': {
        textDecoration: 'none',
        color: theme.palette.common.black,

        '&:hover': {
          textDecoration: 'none',
          color: theme.palette.primary.main,
        },
      },
      boxShadow: 'none',
      backgroundColor: 'transparent',
    },
  })();

  const { categorytype, category: codedCategory } = useParams();
  const category = codedCategory?.replace(/-/g, ' ');

  // Helper function to turn the tag filters into URL parameters.
  function buildURLTagFiltersParams(
    object: TagFiltersTypes
  ): Record<string, string> {
    const buildURLParamsObject: Record<string, string> = {};
    Object.entries(object).forEach(([key, value]) => {
      buildURLParamsObject[key] = value.join(',');
    });
    return buildURLParamsObject;
  }

  // Retrieve information about the store and collections that were requested in the app.
  const retailerInformation = useContext(RetailerInformationContext);
  const { tagCategories } = useContext(ApplicationContext);

  const categoryDict = useMemo(() => {
    const r: Record<string, { category: number; value: number }> = {};
    tagCategories?.forEach((c) => {
      c.tags.forEach((t) => {
        r[`${c.name}/${t.name}`] = {
          category: c.id,
          value: t.id,
        };
      });
    });
    return r;
  }, [tagCategories]);

  // Make information easier to use.
  const storeId = retailerInformation?.store.id;
  const tenantId = retailerInformation?.store.tenant.id;
  const availableResultsPerPage = [18, 24, 36, 48];

  const [searchURLParams, setSearchURLParams] = useSearchParams();
  const { search, pageNumber, pageSize, featuredProductSearch, vip, ...rest } =
    Object.fromEntries([...Array.from(searchURLParams)]);

  // In the mobile fiew the filters are a popup instead of a side nav.
  const [displayMobileFilter, setDisplayMobileFilter] =
    useState<boolean>(false);

  // Page size, until we get infinite scroll.
  const [resultsPerPage, setResultsPerPage] = useState<number>(
    parseInt(pageSize, 10) || availableResultsPerPage[0]
  );

  // This extracts the search from the url information.  Its memoized so we are not
  // executing this function any more than necessary.  The function is not computationally
  // complex, so this is not really necessary.
  const searchParams = useMemo(() => {
    const queryTagFilters: TagFiltersTypes = {};

    // Retrieve the query tag filters from the URL
    Object.entries(rest).forEach(([key, value]) => {
      // helps to remove tracking appended query such as fbclid
      const parsedKey = parseInt(key, 10);
      if (!Number.isNaN(parsedKey)) {
        queryTagFilters[key] = value.split(',');
      }
    });

    // Add in the category to the query tag filters if it is defined
    if (
      categorytype &&
      categorytype.toLowerCase() !== 'collections' &&
      category
    ) {
      const key = `${categorytype}/${category}`;
      const v = categoryDict[key];
      if (v) {
        queryTagFilters[v.category] = [String(v.value)];
      }
    }

    let collectionName: string | undefined;
    let vipCode: string | undefined;
    if (category && categorytype?.toLowerCase() === 'collections') {
      collectionName = category;
      vipCode = vip;
    }

    return {
      searchTerm: search || null,
      tagFilters: queryTagFilters,
      pageNumber: parseInt(pageNumber, 10) || 1,
      resultsPerPage,
      featuredProductSearch: featuredProductSearch
        ? featuredProductSearch === 'true'
        : false,
      collectionName,
      vipCode,
    } as InventorySearchParams;
  }, [
    category,
    categorytype,
    rest,
    categoryDict,
    featuredProductSearch,
    pageNumber,
    resultsPerPage,
    search,
    vip,
  ]);

  // For simplicity the inventory search uses some ids that are not valid.
  // This removes those ids.  Its a bit hacky, but we are trying to isolate changes
  // to within the feature flag.
  const cleanedSearchParams: InventorySearchParams = {
    ...searchParams,
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    tagFilters: { ...searchParams.tagFilters, '-1': undefined! },
  };

  // Grab the inventory from the server
  const { inventorySearch, isLoading: inventorySearchIsLoading } =
    useGetInventorySearch(tenantId, storeId, cleanedSearchParams);

  useEffect(() => {
    // If sharing a url with featuredProductSearch=true but displayFeatured is false change query param to featuredProductSearch=false
    if (
      inventorySearch?.displayFeatured !== undefined &&
      !inventorySearch?.displayFeatured
    ) {
      searchURLParams.set('featuredProductSearch', 'false');
      setSearchURLParams(searchURLParams, { replace: true });
    }
  }, [
    inventorySearch?.displayFeatured,
    featuredProductSearch,
    searchURLParams,
    setSearchURLParams,
  ]);

  // This removes the category that is navigated from the items in the filter view.
  // If you have selected a specific color in the navigation, then color shouldn't show up in the filters.
  const visibleCategories = [
    ...(tagCategories?.filter((n) => n.name !== categorytype) ?? []),
  ];

  // TODO: Ask if there is any reason in the future to retain this logic now
  // that we aren't listing collections in the FilterList
  // If collections aren't in the navigation add them into the filter list.
  // We give collections a special id of -1 so that it doesn't conflict with the filters from the
  // server and we can find it later.
  // if (categorytype?.toLowerCase() !== 'collections' && collections?.length) {
  // const collectionTagCategory: TagCategoryType = {
  //   id: -1,
  //   name: 'Collections',
  //   tags: collections.map((n) => {
  //     return {
  //       // Here we are turning these ids into a negative so they don't conflict with the filter ids.
  //       id: -1 * n.id,
  //       name: n.name,
  //     } as TagType;
  //   }),
  // };
  // This logic renders Collections in the FilterList categories prop.
  // visibleCategories.unshift(collectionTagCategory);
  // }

  // Form to track the search term.
  const {
    handleSubmit,
    register,
    trigger,
    formState: { errors },
    setValue,
  } = useForm<{ search: string }>({
    reValidateMode: 'onSubmit',
  });

  // Handles a change in the search term.
  const onSubmit = (form: { search: string }): void => {
    const searchValue = form.search.trim();
    setValue('search', searchValue.trim());
    trigger();
    if (searchValue.length > 1 || searchValue.length === 0) {
      if (displayMobileFilter) setDisplayMobileFilter(!displayMobileFilter);
      setSearchURLParams({
        ...buildURLTagFiltersParams(searchParams.tagFilters),
        search: searchValue,
        pageNumber: '1',
        pageSize: searchParams.resultsPerPage.toString(),
        featuredProductSearch: searchParams.featuredProductSearch.toString(),
      });
    }
  };

  // Called from the mobile view.  That view has a reset filters button.
  const resetFilters = (): void => {
    setValue('search', '');
    setSearchURLParams({
      tagFilters: [],
      search: '',
      pageNumber: '1',
      pageSize: resultsPerPage.toString(),
      featuredProductSearch: 'false',
    });
  };
  const handleCheckboxSelect = (
    event: ChangeEvent<HTMLInputElement>,
    filterProp: number
  ): void => {
    const { name } = event.target;
    let tagFiltersValue: TagFiltersTypes = {};

    if (searchParams.tagFilters[filterProp]) {
      tagFiltersValue = {
        ...searchParams.tagFilters,
        [filterProp]: searchParams.tagFilters[filterProp].includes(name)
          ? searchParams.tagFilters[filterProp].filter((e) => e !== name)
          : [...searchParams.tagFilters[filterProp], name],
      };
    } else {
      tagFiltersValue = {
        ...searchParams.tagFilters,
        [filterProp]: [name],
      };
    }

    // Remove key value pairs with empty arrays
    tagFiltersValue = JSON.parse(
      JSON.stringify(tagFiltersValue, (key, value) =>
        typeof value === 'object' && value.length < 1 ? undefined : value
      )
    );

    setSearchURLParams({
      ...buildURLTagFiltersParams(tagFiltersValue),
      search: searchParams.searchTerm || '',
      pageNumber: '1',
      pageSize: searchParams.resultsPerPage.toString(),
      featuredProductSearch: searchParams.featuredProductSearch.toString(),
    });
  };
  // Paging functions
  const onResultsPerPage = (event: ChangeEvent<{ value: string }>): void => {
    setResultsPerPage(parseInt(event.target.value as string, 10));
    setSearchURLParams({
      ...buildURLTagFiltersParams(searchParams.tagFilters),
      search: searchParams.searchTerm || '',
      pageNumber: '1',
      pageSize: event.target.value,
      featuredProductSearch: searchParams.featuredProductSearch.toString(),
    });
  };
  const handlePaginate = (paginateNumber: number): void => {
    setSearchURLParams({
      ...buildURLTagFiltersParams(searchParams.tagFilters),
      search: searchParams.searchTerm || '',
      pageNumber: paginateNumber.toString(),
      pageSize: searchParams.resultsPerPage.toString(),
      featuredProductSearch: searchParams.featuredProductSearch.toString(),
    });
  };
  const handleFeatureCheckboxSelect = (): void => {
    setSearchURLParams({
      ...buildURLTagFiltersParams(searchParams.tagFilters),
      search: searchParams.searchTerm || '',
      pageNumber: '1',
      pageSize: searchParams.resultsPerPage.toString(),
      featuredProductSearch: (
        featuredProductSearch === undefined || featuredProductSearch === 'false'
      ).toString(),
    });
  };

  // Toggle the mobile filter panel.
  const mobileFilterToggle = (clear: boolean): void => {
    document.body.classList.add('overflow-hidden');

    if (clear) {
      resetFilters();
    } else {
      setDisplayMobileFilter(!displayMobileFilter);

      if (displayMobileFilter) {
        document.body.classList.remove('overflow-hidden');
      }
    }
  };

  return (
    <div className="col-12">
      <div className="row">
        <div className="col-md-3">
          <form
            data-testid="product_search_form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
          >
            <TextField
              id="product_search_input"
              className="mb-3"
              label="Search"
              variant="outlined"
              type="text"
              autoComplete="off"
              fullWidth
              defaultValue={search}
              inputProps={{
                maxLength: 255,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="search inventory"
                      onClick={handleSubmit(onSubmit)}
                      onMouseDown={handleSubmit(onSubmit)}
                    >
                      <IconSearch id="inventory-search" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              {...register('search', {
                pattern: {
                  message: 'Please fill out field',
                  value: /[^\s]/,
                },
                minLength: {
                  message: 'Minimum 2 characters required',
                  value: 2,
                },
                validate: {
                  noEmoji: (value) =>
                    !emojiCheck(value) || 'Emojis are invalid',
                },
              })}
              helperText={errors.search?.message}
              error={!!errors.search}
            />
          </form>
          <FilterTags
            searchParams={searchParams}
            tagFilters={visibleCategories}
            resetFilters={resetFilters}
          />
          <Button
            className="d-md-none mb-3"
            variant="contained"
            color="primary"
            fullWidth
            onClick={() => mobileFilterToggle(false)}
          >
            Filter
          </Button>
        </div>
        <div className="col-md-9 d-md-block">
          <div className="row mb-3">
            <div className="col-12">
              <div className="d-flex align-items-center">
                <div className="flex-shrink-1">
                  <div>
                    <Breadcrumbs separator=">">
                      <Link
                        color="inherit"
                        className="text-decoration-underline"
                        to="/shop"
                        aria-current={!category ? 'true' : 'false'}
                      >
                        Shop
                      </Link>
                      {category && (
                        <Typography aria-current="page">{category}</Typography>
                      )}
                    </Breadcrumbs>
                  </div>
                  <div>
                    {!inventorySearchIsLoading &&
                      inventorySearch &&
                      `${inventorySearch.currentPage.totalRecords} ${
                        inventorySearch.currentPage.totalRecords === 1
                          ? 'Result'
                          : 'Results'
                      }`}
                  </div>
                </div>
                <div className="d-flex flex-grow-1 align-items-center justify-content-end">
                  <InputLabel
                    id="resultsPerPage"
                    className="m-0 pe-3 d-inline-block"
                  >
                    Results Per Page
                  </InputLabel>
                  <TextField
                    id="resultsPerPage"
                    select
                    value={resultsPerPage}
                    onChange={onResultsPerPage}
                    variant="outlined"
                    className="d-inline-block"
                  >
                    {availableResultsPerPage.map((item) => (
                      <MenuItem key={item} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </TextField>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div
          className={clsx('col-md-3 d-md-block', {
            'd-none': !displayMobileFilter,
          })}
        >
          <FilterList
            categories={visibleCategories}
            displayFeatured={inventorySearch?.displayFeatured}
            loading={inventorySearchIsLoading}
            onFeaturedChange={handleFeatureCheckboxSelect}
            featuredState={featuredProductSearch === 'true'}
            onChange={handleCheckboxSelect}
            tagFilters={searchParams.tagFilters}
            mobileFilterToggle={mobileFilterToggle}
          />
        </div>
        {inventorySearchIsLoading ? (
          <div className="col-md-9">
            <div className={`${Styles.gutterLeft} row`}>
              {[...Array(resultsPerPage)].map(
                (value: undefined, index: number) => (
                  <div
                    key={index}
                    className="col-xl-2 col-lg-3 col-sm-4 col-6 ps-0 pb-4"
                    data-testid="loading-item-skeleton"
                  >
                    <Skeleton inline className={Styles.productGroupSkeleton} />
                    <Skeleton inline count={2} className="mt-2" />
                  </div>
                )
              )}
            </div>
          </div>
        ) : (
          <div className="col-md-9">
            <div className={`${Styles.gutterLeft} row`}>
              {inventorySearch?.currentPage.records.length === 0 && (
                <p className="col-12 ps-0 text-center fw-bold">
                  We couldn&apos;t find any matching products. Please try
                  another search or change your filters.
                </p>
              )}
              {inventorySearch?.currentPage.records.map(
                (inventoryItem: InventoryRecord) => (
                  <div
                    key={`product_group_card_${inventoryItem.productGroupId}`}
                    className="col-xl-2 col-lg-3 col-sm-4 col-6 ps-0 pb-4"
                  >
                    <Card
                      className={styles.productGroupItem}
                      data-testid="inventory-item"
                    >
                      <Link
                        key={inventoryItem.productGroupId}
                        to={`/products/${inventoryItem.productGroupId}`}
                      >
                        <ImageCover
                          objectFit="contain"
                          className={styles.productGroupImage}
                          src={
                            inventoryItem.thumbnailImageUrl || PlaceholderImage
                          }
                          alt={inventoryItem.name}
                        />
                        <div className={`${Styles.productGroupName} mb-0`}>
                          {inventoryItem.name}
                        </div>
                        {(inventoryItem.priceLow > 0 ||
                          inventoryItem.priceHigh > 0) && (
                          <div className={`${Styles.productGroupPrice} mb-0`}>
                            {RangeFormatter.format(
                              inventoryItem.priceLow,
                              inventoryItem.priceHigh
                            )}
                          </div>
                        )}
                      </Link>
                    </Card>
                  </div>
                )
              )}
            </div>
          </div>
        )}
      </div>
      {inventorySearch && (
        <div className="row mt-3 justify-content-end">
          <div className="col-md-9">
            <PaginationControls
              currentPage={inventorySearch.currentPage.currentPage}
              itemCount={inventorySearch.currentPage.totalRecords}
              itemsPerPage={inventorySearch.currentPage.pageSize}
              onChange={handlePaginate}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductInventoryCollections;

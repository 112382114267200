import React, { FC, useContext, useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom'; // Add useLocation to track the current path
import clsx from 'clsx';
import Skeleton from 'react-loading-skeleton';
import Button from '@material-ui/core/Button';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Menu, withStyles } from '@material-ui/core';

import Fade from '@material-ui/core/Fade';

import DownCarrotIcon from '../Icons/DownCarrotIcon';

import CartContext from '../../context/CartContext';
import useAlerts from '../../context/Alerts/useAlerts';
import { useSession } from '../../context/SessionProvider';
import MyStory from '../../types/MyStory';
import ShoppingCart from '../ShoppingCart';
import OutsideAlerter from '../ClickOutside';
import CountdownTimer from '../CountdownTimer';
import IconShoppingCart from '../Icons/IconShoppingCart';
import Styles from './Navigation.module.scss';
import ApplicationContext from '../../context/ApplicationContext';

interface NavigationProps {
  dsoName?: string;
  dsoLogoUrl?: string;
  myStoryPage?: MyStory;
  storeName?: string;
  byDesignRepDID?: string;
  toggleCartDisplay: () => void;
  displayCart: boolean;
}

type NavLink = {
  id: string;
  url?: string;
  linkText: string;
  external?: boolean;
  children?: NavLink[];
  suppressActive?: boolean;
};

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
})((props: any) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const NavigationCollection: FC<NavigationProps> = ({
  dsoLogoUrl,
  dsoName,
  myStoryPage,
  storeName,
  byDesignRepDID,
  toggleCartDisplay,
  displayCart,
}) => {
  const cart = useContext(CartContext);
  const { collections, tagCategories } = useContext(ApplicationContext);
  const { generateNewSessionId } = useSession();
  const [menuOpen, setMenuOpen] = useState<{
    id: string;
    anchor: null | HTMLElement;
  }>({ id: '', anchor: null });

  const location = useLocation();
  const isShopping = location.pathname?.startsWith('/shop');

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClose = () => {
    setMenuOpen({ id: '', anchor: null });
  };

  const theme = useTheme();
  const muiStyles = makeStyles({
    navLink: {
      '& span': {
        '&:hover': {
          borderBottom: `3px solid ${theme.palette.primary.main}`,
        },
      },
    },
    activeLink: {
      '& span': {
        borderBottom: `3px solid ${theme.palette.primary.main}`,
      },
    },
  })();

  const navLinks: NavLink[] = [
    {
      id: 'store',
      linkText: storeName ? `${storeName}'s Store` : 'Store',
      url: '/shop',
    },
  ];

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const createUrl = (parentId: string, childId?: string) => {
    return `/shop/${encodeURIComponent(parentId)}/${encodeURIComponent(
      childId ?? ''
    )}`;
  };

  // TODO: This is pending a change by design
  // if (isShopping) {
  //   navLinks.push({
  //     id: 'shop_all',
  //     linkText: 'Shop All',
  //     url: '/shop',
  //     suppressActive: true,
  //   });
  // }

  if (isShopping && tagCategories?.length) {
    const newNavLinks = tagCategories
      .filter((tc) => tc.isNavigation)
      .map((tc) => ({
        id: tc.id.toString(),
        linkText: tc.name,
        children: tc.tags?.map(
          (t) =>
            ({
              id: t.id.toString(),
              linkText: t.name,
              url: createUrl(tc.name, t.name),
            } as NavLink)
        ),
      }));
    navLinks.push(...newNavLinks);
  }

  if (isShopping && collections?.length) {
    navLinks.push({
      id: 'collections',
      linkText: 'Collections',
      children: collections.map(
        (c) =>
          ({
            id: String(c.id),
            linkText: c.name,
            url: createUrl('collections', c.name),
          } as NavLink)
      ),
    });
  }

  navLinks.push({ id: 'register', linkText: 'Register', url: '/register' });

  if (
    myStoryPage &&
    Object.values(myStoryPage).some((x) => x !== null && x !== '')
  ) {
    navLinks.push({
      id: 'myStory',
      linkText: myStoryPage.pageName || 'My Story',
      url: '/about',
    });
  }

  if (byDesignRepDID) {
    navLinks.push({
      id: 'join',
      linkText: 'Join My Team',
      url: `https://paparazziaccessories.com/join/${byDesignRepDID}/#/`,
      external: true,
    });
  }

  const [displayMobileNav, setDisplayMobileNav] = useState(false);
  const [inTransition, setInTransition] = useState(false);
  const [totalQuantities, setTotalQuantities] = useState<number>();
  const { sendAlert } = useAlerts();

  useEffect(() => {
    if (cart?.validationMessage) {
      sendAlert(cart.validationMessage, 'error');
    }

    if (cart?.items && cart.items?.length > 0) {
      let total = 0;
      cart.items.forEach((cartItem) => {
        total += cartItem.quantity;
      });
      setTotalQuantities(total);
    }
  }, [cart, sendAlert]);

  const closeMenu = (): void => {
    handleClose();
    if (displayMobileNav) setInTransition(true);
    setDisplayMobileNav(false);
    setTimeout(() => {
      setInTransition(false);
      document.body.classList.remove('overflow-hidden');
    }, 300); // 0.3s for css animation duration
  };

  const toggleMenu = (): void => {
    // eslint-disable-next-line no-unused-expressions
    document.body.classList.contains('overflow-hidden')
      ? document.body.classList.remove('overflow-hidden')
      : document.body.classList.add('overflow-hidden');

    // eslint-disable-next-line no-unused-expressions
    displayMobileNav ? closeMenu() : setDisplayMobileNav(true);
  };

  const dsoLogo = dsoLogoUrl ? (
    <img
      src={dsoLogoUrl}
      alt={`${dsoName || 'Store'} Logo`}
      className="d-inline-block"
    />
  ) : (
    <Skeleton count={1} className={Styles.nav_logo__loading} />
  );

  const handleOpenMenu: (
    e: React.MouseEvent<HTMLElement>,
    c: NavLink
  ) => void = (e: React.MouseEvent<HTMLElement>, c: NavLink) => {
    setMenuOpen({ id: c.id, anchor: e.currentTarget });
  };

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const createLink = (myStoreName: string | undefined, link: NavLink) => {
    if (!myStoreName) {
      return (
        <li key={link.linkText} className={Styles.nav_link}>
          <span className={Styles.nav_link__text}>
            <Skeleton
              count={1}
              className={`${Styles.nav_link__loading} d-inline-block`}
              width={150}
            />
          </span>
        </li>
      );
    }

    if (link.children?.length) {
      const selectedStyle =
        link.id === menuOpen.id ? muiStyles.activeLink : null;
      return (
        <li key={link.linkText}>
          <Typography
            className={clsx(
              Styles.nav_link,
              muiStyles.navLink,
              selectedStyle,
              Styles.dropdownsmall
            )}
            onClick={(e: React.MouseEvent<HTMLElement>) =>
              handleOpenMenu(e, link)
            }
            style={{ cursor: 'pointer' }}
          >
            <span className={Styles.nav_link__text}>{link.linkText}</span>
            <DownCarrotIcon className={Styles.nav_link__text} />
          </Typography>
          <StyledMenu
            id="basic-menu"
            anchorEl={menuOpen.anchor}
            open={menuOpen.id === link.id && !!menuOpen.anchor}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            TransitionComponent={Fade}
          >
            {link.children.map((c) => (
              <NavLink
                key={c.url ?? c.id}
                to={c.url ?? '/'}
                className={({ isActive }) =>
                  clsx(Styles.nav_link, muiStyles.navLink, {
                    [Styles.nav_link__active]: isActive,
                  })
                }
                onClick={closeMenu}
              >
                <span className={Styles.nav_link__text}>{c.linkText}</span>
              </NavLink>
            ))}
          </StyledMenu>
        </li>
      );
    }

    if (link.url) {
      return (
        <li key={link.linkText}>
          <NavLink
            target={link.external ? '_blank' : '_self'}
            to={link.url}
            className={({ isActive }) =>
              clsx(Styles.nav_link, muiStyles.navLink, {
                [Styles.nav_link__active]: isActive && !link.suppressActive,
              })
            }
            onClick={closeMenu}
          >
            <span className={Styles.nav_link__text}>{link.linkText}</span>
          </NavLink>
        </li>
      );
    }

    return (
      <li key={link.linkText}>
        <Typography className={Styles.nav_link} onClick={closeMenu}>
          <span className={Styles.nav_link__text}>{link.linkText}</span>
        </Typography>
      </li>
    );
  };

  return (
    <div
      className={clsx(Styles.nav_background, {
        [Styles.mobile_nav]: displayMobileNav,
        [Styles.mobile_nav__transition]: inTransition,
      })}
    >
      <Button
        className={`${Styles.menu_button} d-md-none position-absolute`}
        onClick={toggleMenu}
        startIcon={
          <div className={Styles.hamburger_icon}>
            <span />
            <span />
            <span />
          </div>
        }
      />
      <NavLink
        to="/shop"
        className={`${Styles.nav_link} ${Styles.nav_logo} ${Styles.nav_logo_mobile} d-md-none`}
      >
        {dsoLogo}
      </NavLink>
      <div className={Styles.nav_wrapper}>
        <nav className={Styles.nav}>
          <ul>
            <li>
              <NavLink
                to="/shop"
                className={`${Styles.nav_link} ${Styles.nav_logo}`}
                onClick={closeMenu}
              >
                {dsoLogo}
              </NavLink>
            </li>
            {navLinks?.map((link) => createLink(storeName, link))}
          </ul>
        </nav>
        <OutsideAlerter
          clickOutside={toggleCartDisplay}
          displayCart={displayCart}
        >
          <div className={Styles.cart_wrapper}>
            <Button
              color="primary"
              variant={
                cart && cart.items && cart.items.length > 0
                  ? 'contained'
                  : 'text'
              }
              className={clsx('mx-3', Styles.nav_cart_button, {
                [Styles.nav_cart_button__active]:
                  cart && cart.items && cart.items.length > 0,
              })}
              onClick={toggleCartDisplay}
            >
              {cart?.items && cart.items.length > 0 && (
                <>
                  {cart?.expires && (
                    <CountdownTimer
                      className={Styles.timer}
                      expirationDate={cart.expires}
                      onTimeZero={() => {
                        if (cart && cart.items) generateNewSessionId();
                      }}
                    />
                  )}
                  {totalQuantities && (
                    <span className="ms-3 me-2">
                      {totalQuantities}
                      <span className="d-none d-sm-inline">
                        {' '}
                        Item
                        {totalQuantities > 1 && 's'}
                      </span>
                    </span>
                  )}
                </>
              )}
              <IconShoppingCart id="navigationShoppingCartIcon" />
            </Button>
            {displayCart && cart && (
              <div className={Styles.shoppingCartPreview}>
                <ShoppingCart handleClick={toggleCartDisplay} />
              </div>
            )}
          </div>
        </OutsideAlerter>
      </div>
      <div className={Styles.close_background} onClick={closeMenu} />
    </div>
  );
};

export default NavigationCollection;
